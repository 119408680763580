<template>
  <div class="wrapper">
    <div class="page">
      <div id="loader"></div>
      <div class="page-inner" id="main_page">

        <div class="va-card">
          <div class="row">

            <div class="col-lg-12">
              <div class="dash_card_body">

                <div class="header_section">
                  <h4 class="menu_header">Allergen Icons</h4>
                  <div class="">

                    <button data-toggle="modal" data-target="#add_allergen" class="btn btn-default custom_btn custon_orange">
                      <i class="fa fa-plus"></i> Add New
                    </button>

                  </div>
                </div>

                <!-- create icons modal -->
                <div class="modal fade" id="add_allergen" role="dialog">
                  <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                      <div class="modal-body">
                        <h2>Create Allergen</h2>
                        <button type="button" id="allergen_close" class="close" data-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                        </button>
                        <div class="bd-b">
                          <div class="form-group">
                            <label>Title <span style="color:red">*</span> </label>
                            <div>
                              <input class="form-control" v-model="title" @blur="v$.title.$touch">
                            </div>
                            <div class="input_error" v-if="v$.title.$error">This field is required.</div>
                          </div>

                          <div class="form-group">
                            <label>Status <span style="color:red">*</span></label>
                            <div>
                              <select class="form-control" v-model="status" @blur="v$.status.$touch">
                                <option value="1">Published</option>
                                <option value="0">Unpublished</option>
                              </select>
                            </div>
                            <div class="input_error" v-if="v$.status.$error">This field is required.</div>
                          </div>

                          <div class="form-group">
                            <label>Attribute Name <span style="color:red">*</span></label>
                            <div>
                              <select class="form-control" v-model="attributeName" @blur="v$.attributeName.$touch">
                                <option value=""> --Select Attribute--</option>
                                <option value="spicy">spicy</option>
                                <option value="spicy1">spicy1</option>
                                <option value="spicy2">spicy2</option>
                                <option value="spicy3">spicy3</option>
                                <option value="vegan">vegan</option>
                                <option value="vegetarian">vegetarian</option>
                                <option value="kosher">kosher</option>
                                <option value="halal">halal</option>
                                <option value="gluten_free">gluten free</option>
                                <option value="lactose_free">lactose free</option>
                                <option value="children">children</option>
                                <option value="alco">alco</option>
                                <option value="special_offer">special offer</option>
                                <option value="no_discount">no discount</option>
                                <option value="bottle_deposit">bottle deposit</option>
                                <option value="donation">donation</option>
                                <option value="packaging_fee">packaging fee</option>
                                <option value="caffeine_free">caffeine free</option>
                                <option value="contains_sesame">contain sesame</option>
                                <option value="contains_nuts">contains nuts</option>
                                <option value="recommended">recommended</option>
                                <option value="cold_drink">Cold Drink</option>
                                <option value="hot_drink">Hot Drink</option>
                                <option value="sea_food">Sea Food</option>
                                <option value="dessert">Dessert</option>
                                <option value="egg">Egg</option>
                                <option value="pork">Pork</option>
                              </select>
                            </div>
                            <div class="input_error" v-if="v$.attributeName.$error">This field is required.</div>
                          </div>
                        </div>
                        <!-- image -->

                        <div id="foodie-loading-cover" class="pt-4 mb-4">
                          <span>
                            <label data-baseweb="form-control-label">Photo</label>
                          </span>
                          <div data-baseweb="form-control" class="al dw">
                            <div class="image_div">
                              <div class="img_preview" style="">
                                <div tabindex="0" style="display: flex; justify-content: center; width: 100%; height: 100%; border: 2px dashed rgb(229, 229, 229); background: rgb(247, 247, 247) none repeat scroll 0% 0%; flex-direction: column; align-items: center; padding: 20px 0px; box-sizing: border-box;">
                                  <img class="image_responsive" v-if="images" :src="images" />
                                </div>
                              </div>
                              <div class="image_content">
                                <p>File requirement: JPG, PNG, GIF, or WEBP up to 10MB.
                                  Minimum pixels required: 320 for width and height.</p>

                                <div class="image_button">

                                  <div class="add_image_btn_grp" @click="openFileUploadDialog()">
                                    <div class="btn_plus_icon">
                                      <input id='fileid' type='file' @change="uploadImage($event, 2484, 1380)" hidden />
                                      <i class="fa fa-plus" style="font-size:24px"></i>
                                    </div>
                                    <div class="btn_url">
                                      <span>Add from URL</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div id="foodie-loader"> </div>
                        </div>
                      </div>
                      <div class="modal-footer">
                        <button type="button" class="btn btn-danger" data-dismiss="modal">Cancel</button>
                        <button type="button" class="btn btn-success" @click="createIcons()" data-dismiss="modal"> <span v-if="spinner"><i class='fa fa-spinner fa-spin '></i> Creating..</span> <span v-else>Create</span></button>
                      </div>
                    </div>
                  </div>
                </div>



                <div class="search_section">
                  <div class="row">
                    <div class="col-md-5">
                      <div class="form-group has-search mt-1">
                        <span class="fa fa-search form-control-feedback"></span>
                        <input type="text" v-model="search" @keyup="searchIcons()" aria-required="false" autocomplete="on" inputmode="text" name="search_input" placeholder="Search..." class="form-control">
                      </div>
                    </div>
                    <div class="col-md-5">
                      <select class="form-control mt-1" @change="searchIcons()" v-model="searchStatus">
                        <option value="">--Select status --</option>
                        <option value="1">Published</option>
                        <option value="0">Unpublished</option>
                      </select>
                    </div>

                  </div>
                </div>
              </div>
              <hr>
            </div>

            <div class="col-lg-12">
              <div class="dash_card_body pt-0">
                <div class="table_section">
                  <table class="table  table-hover">
                    <thead>
                      <tr>
                        <th scope="col"></th>
                        <th scope="col">Name</th>
                        <th scope="col">Status</th>
                        <th scope="col">Last Updated</th>
                        <th scope="col">Attribute Name</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody v-if="!loading">
                      <tr v-for="icon in icons" :key="icon.id">
                        <td>
                          <span v-if="icon.image_url">
                            <img style="border-radius:3px;vertical-align: middle;" width="40" height="40" :src="icon.image_url" alt=""> &nbsp;
                          </span>
                          <span v-else>
                            <img style="border-radius:3px;vertical-align: middle;" width="40" height="40" src="../../../../public/img/no_images.jpg" alt=""> &nbsp;
                          </span>
                        </td>
                        <td>
                          {{icon.name}}
                        </td>
                        <td>
                          <span :class="icon.status == 1 ?'active' : 'disabled'">{{icon.status == 1? 'Published': 'Unpublished'}}</span>
                        </td>
                        <td>
                          {{formatDate(icon.updated_at,1)}}
                        </td>
                        <td>
                          {{icon.attribute_name}}
                        </td>
                        <td>
                          <button class="btn btn-primary btn-sm mr-1" @click="getIconDetails(icon)" data-toggle="modal" :data-target="'#update_allergen_'+icon.id"><i class='fas fa-edit'></i></button>
                        </td>

                        <!-- update modal -->

                        <div class="modal fade" :id="`update_allergen_${icon.id}`" role="dialog">
                          <div class="modal-dialog modal-lg">
                            <div class="modal-content">
                              <div class="modal-body">
                                <h2>Update Allergen {{icon.name}}</h2>
                                <button type="button" :id="`allergen_close_${icon.id}`" class="close" data-dismiss="modal" aria-label="Close">
                                  <span aria-hidden="true">&times;</span>
                                </button>
                                <div class="bd-b">
                                  <div class="form-group">
                                    <label>Title <span style="color:red">*</span></label>
                                    <div>
                                      <input class="form-control" v-model="title" @blur="v$.title.$touch">
                                    </div>
                                    <div class="input_error" v-if="v$.title.$error">This field is required.</div>
                                  </div>

                                  <div class="form-group">
                                    <label>Status <span style="color:red">*</span></label>
                                    <div>
                                      <select class="form-control" v-model="status" @blur="v$.status.$touch">
                                        <option value="1">Published</option>
                                        <option value="0">Unpublished</option>
                                      </select>
                                    </div>
                                    <div class="input_error" v-if="v$.status.$error">This field is required.</div>
                                  </div>

                                  <div class="form-group">
                                    <label>Attribute Name <span style="color:red">*</span></label>
                                    <div>
                                      <select class="form-control" v-model="attributeName" @blur="v$.attributeName.$touch">
                                        <option value=""> --Select Attribute--</option>
                                        <option value="spicy">spicy</option>
                                        <option value="spicy1">spicy1</option>
                                        <option value="spicy2">spicy2</option>
                                        <option value="spicy3">spicy3</option>
                                        <option value="vegan">vegan</option>
                                        <option value="vegetarian">vegetarian</option>
                                        <option value="kosher">kosher</option>
                                        <option value="halal">halal</option>
                                        <option value="gluten_free">gluten free</option>
                                        <option value="lactose_free">lactose free</option>
                                        <option value="children">children</option>
                                        <option value="alco">alco</option>
                                        <option value="special_offer">special offer</option>
                                        <option value="no_discount">no discount</option>
                                        <option value="bottle_deposit">bottle deposit</option>
                                        <option value="donation">donation</option>
                                        <option value="packaging_fee">packaging fee</option>
                                        <option value="caffeine_free">caffeine free</option>
                                        <option value="contains_sesame">contain sesame</option>
                                        <option value="contains_nuts">contains nuts</option>
                                        <option value="recommended">recommended</option>
                                        <option value="cold_drink">Cold Drink</option>
                                        <option value="hot_drink">Hot Drink</option>
                                        <option value="sea_food">Sea Food</option>
                                        <option value="dessert">Dessert</option>
                                        <option value="egg">Egg</option>
                                        <option value="pork">Pork</option>
                                      </select>
                                    </div>
                                    <div class="input_error" v-if="v$.attributeName.$error">This field is required.</div>
                                  </div>
                                </div>
                                <!-- image -->

                                <div id="foodie-loading-cover" class="pt-4 mb-4">
                                  <span>
                                    <label data-baseweb="form-control-label">Photo</label>
                                  </span>
                                  <div data-baseweb="form-control" class="al dw">
                                    <div class="image_div">
                                      <div class="img_preview" style="">
                                        <div tabindex="0" style="display: flex; justify-content: center; width: 100%; height: 100%; border: 2px dashed rgb(229, 229, 229); background: rgb(247, 247, 247) none repeat scroll 0% 0%; flex-direction: column; align-items: center; padding: 20px 0px; box-sizing: border-box;">
                                          <img class="image_responsive" v-if="images" :src="images" />
                                        </div>
                                      </div>
                                      <div class="image_content">
                                        <p>File requirement: JPG, PNG, GIF, or WEBP up to 10MB.
                                          Minimum pixels required: 320 for width and height.</p>

                                        <div class="image_button">

                                          <div class="add_image_btn_grp" @click="openFileUploadDialog()">
                                            <div class="btn_plus_icon">
                                              <input id='fileid' type='file' @change="uploadImage($event, 2484, 1380)" hidden />
                                              <i class="fa fa-plus" style="font-size:24px"></i>
                                            </div>
                                            <div class="btn_url">
                                              <span>Add from URL</span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div id="foodie-loader"> </div>
                                </div>
                              </div>
                              <div class="modal-footer">
                                <button type="button" class="btn btn-danger" data-dismiss="modal">Cancel</button>
                                <button type="button" class="btn btn-success" @click="updateIcons(icon.id)" data-dismiss="modal"> <span v-if="spinner"><i class='fa fa-spinner fa-spin '></i> Creating..</span> <span v-else>Create</span></button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!--  -->
                      </tr>
                    </tbody>
                    <tfoot v-if="!loading">
                      <tr>
                        <td colspan="8">
                          <div id="pagination" v-if="totalPages > 1">
                            <ul class="showItems">
                              <li>Show Items:
                                <select @change="onChangeRecordsPerPage" v-model="recordsPerPage">
                                  <option :value="10">10</option>
                                  <option :value="20">20</option>
                                  <option :value="30">30</option>
                                  <option :value="40">40</option>
                                  <option :value="50">50</option>
                                </select>
                              </li>

                            </ul>
                            <Pagination :total-pages="totalPages" :per-page="recordsPerPage" :current-page="page" @pagechanged="onPageChange" />

                          </div>

                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>

              <div v-if="loading" class="loading-cover">
                <div class="loader"> </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>



<script>
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { useToast } from "vue-toastification";
import ReusableDataMixins from '@/mixins/ReusableDataMixins';
import ReusableFunctionMixins from '@/mixins/ReusableFunctionMixins'
import Pagination from '@/pages/pagination/pagination.vue';
const toast = useToast();
export default {
    components: {
        Pagination,
    },
    setup() {
        return { v$: useVuelidate() }
    },
    name: 'allergen-icon-page',
    data() {
        return {
            icons: [],
            loading: false,
            page: 1,
            totalPages: 10,
            totalRecords: 0,
            recordsPerPage: 10,
            methodSpinner: null,
            debounce: null,
            search: '',
            title: '',
            attributeName: '',
            status: 1,
            searchStatus: '',
            imageUrl: '',
            images: null,
            spinner: false,
            errors: false,
            errorMsg: []
        }
    },
    created: async function () {
        this.getIcons();
    },
    validations() {
        return {
            title: { required },
            status: { required },
            attributeName: { required }
        }
    },
    methods: {
      searchIcons() {
            clearTimeout(this.debounce);
            this.debounce = setTimeout(() => {
                this.getIcons();
            }, 600)
        },
        getIconDetails(icon){
          this.title = icon.name;
          this.status = icon.status;
          this.attributeName = icon.attribute_name;
          this.imageUrl = icon.image_url;
          this.images = icon.image_url;
        },

        async createIcons() {
            this.spinner = true;
            const isFormCorrect = await this.v$.$validate()
            // you can show some extra alert to the user or just leave the each field to show it's `$errors`.
            if (!isFormCorrect) {
                this.spinner = false;
                return
            }
            try {
                const payload = {
                    'name': this.title,
                    'image_url': this.imageUrl,
                    'status': this.status,
                    'attribute_name': this.attributeName
                }
                const config = {
                    headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
                };
                const url = `${this.dashBaseUrl}/create-allergen-icon/${this.merchantID}`;
                const res = await this.axios.post(url, payload, config)
                if (res.status === 201) {
                    this.spinner = false;
                    document.getElementById('allergen_close').click();
                    toast.success('Allergen created successfully');
                    this.getIcons()
                }
            } catch (error) {
                console.log(error)
                this.spinner = false;
                if (error.response.status === 400) {
                    this.errors = true,
                        this.errorMsg = error.response.errors
                }
            }
        },

        async updateIcons(id) {
            this.spinner = true;
            const isFormCorrect = await this.v$.$validate()
            // you can show some extra alert to the user or just leave the each field to show it's `$errors`.
            if (!isFormCorrect) {
                this.spinner = false;
                return
            }
            try {
                const payload = {
                    'name': this.title,
                    'image_url': this.imageUrl,
                    'status': this.status,
                    'attribute_name': this.attributeName
                }
                const config = {
                    headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
                };
                const url = `${this.dashBaseUrl}/update-allergen-icon/${id}`;
                const res = await this.axios.put(url, payload, config)
                if (res.status === 201) {
                    this.spinner = false;
                    document.getElementById(`allergen_close_${id}`).click();
                    toast.success('Allergen updated successfully');
                    this.getIcons()

                    this.title = '';
                    this.status = 1;
                    this.attributeName = '';
                    this.imageUrl = '';
                    this.images = null;
                }
            } catch (error) {
                console.log(error)
                this.spinner = false;
                if (error.response.status === 400) {
                    this.errors = true,
                        this.errorMsg = error.response.errors
                }
            }
        },

        async getIcons() {
            //
            try{
              const config = {
                    headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
                };
                let url = `${this.dashBaseUrl}/allergen_icons/${this.merchantID}`;
                url = new URL(url);
                url.searchParams.set('page', this.page);
                url.searchParams.set('size', this.recordsPerPage);
                if(this.search !==''){
                  url.searchParams.set('search', this.search);
                }
                if(this.searchStatus !='' ){
                  url.searchParams.set('status',this.searchStatus)
                }
                this.loading = true;
                const res = await this.axios.get(url,config)
                if(res.status === 200){
                  this.icons = res.data.data.data
                  this.totalPages = Math.ceil(res.data.data.total / this.recordsPerPage)
                  this.totalRecords = res.data.data.total
                  this.loading = false;
                }
              }catch(error){
                this.loading = false;
                console.log(error)
              }
        },
        onPageChange(page) {
            this.page = page
            this.getIcons();
        },
        onChangeRecordsPerPage() {
            this.getIcons();
            // ds
        }
    },
    mixins: [ReusableFunctionMixins, ReusableDataMixins]
}
</script>
